export default {
  myPurchases: "Mes Achats",
  paymentMethods: "Modes de Paiement",
  accountInfo: "Informations Sur Le Compte",
  resolutionCenter: "Assistance",
  emailReceipt: "Avis de réception par courriel",
  changePassword: "Changer le mot de passe",
  languageSettings: "Changer la langue",
  deleteAccount: "Supprimer le compte",
  logout: "Déconnexion",
};
			
			
			
			
			
			
			
			