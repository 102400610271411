import React, { useEffect, useContext } from "react";
import AppLayout from "../../appLayout/AppLayout";
import { useHistory } from "react-router-dom";
import {ReactComponent as SvgComponent} from "../../assets/images/authentication/NewPasswordSet.svg";
import { View, StyleSheet } from "react-native-web";
import Button from "../../components/Button";
import H1Title from "../../components/UI/H1Title";
import Paragraph from "../../components/UI/Paragraph";
import { useTranslation } from "react-i18next";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import { AuthContext } from "../../store/auth-context";

export default function ResultMessageScreen({ navigation, route }) {
    const history = useHistory();
    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);

    return (
        <AppLayout
            header={false}
            style={{ backgroundColor: "#fff" }}
        >
            <View style={{ flex: 1, display: "flex" }}>
                <View style={{ alignSelf: "center", marginTop: "24vh" }}>
                    <SvgComponent />
                </View>
                <View style={styles.text_container}>
                    <H1Title style={{ marginTop: 30 }}>{t("resultScreens:successNewPasswordTitle")}</H1Title>
                    <Paragraph>{t("resultScreens:successNewPasswordText")}</Paragraph>
                </View>
                <BottomBtnContainer style={{ flex: 2 }}>
                    <View style={{ marginTop: 24 }}>
                        <Button
                            text={t("buttons:goToPurchaseHistory")}
                            onPress={async () => { await authCtx.authenticate(); history.push("../Purchases") }}
                            type="PRIMARY"
                        />
                    </View>
                </BottomBtnContainer>
            </View>
        </AppLayout>
    );

}

const styles = StyleSheet.create({
    image_container: {
        flex: 4,
        justifyContent: "center",
        alignItems: "center",
    },
    text_container: {
        flex: 2,
        justifyContent: "flex-start",
        paddingTop: 30
    },
});