export default {
  phoneNumber: "Telefoonnummer",
  searchCountry: "Zoek land",
  forgotPassword: "Wachtwoord vergeten?",
  changePassword: "Verander wachtwoord",
  deleteAccountModalTitle: "Weet je zeker dat je wilt vertrekken?",
  deleteAccountModalText:
    "Deze actie verwijdert permanent al uw gegevens volgens ons privacybeleid.",
  name: "Voornaam",
  nameInfo: "Vul je naam in zoals die op je identiteitskaart staat",
  emailAddress: "E-mailadres",
  password: "Wachtwoord",
  newPassword: "Nieuw Paswoord",
  checkTermsAndPrivacy: "Door dit vakje aan te vinken ga ik akkoord met de",
  termsConditions: "algemene voorwaarden",
  and: "en",
  privacyPolicy: "het privacybeleid van reckon.ai.",
  verificationCodeInfoPart1:
    "We hebben een verificatiecode naar je telefoon gestuurd",
  verificationCodeInfoPart2: "De code is geldig voor",
  seconds: "s",
  profileAllSetTitle: "Je profiel is helemaal ingesteld",
  profileAllSetText:
    "Nu je profiel is ingesteld, gaan we je betaalmethode instellen.",
  modalDuplicatedUserTitle: "Het lijkt erop dat je al een account hebt",
  modalDuplicatedUserText01: "De e-mail",
  modalDuplicatedUserText011: "Het telefoonnummer",
  modalDuplicatedUserText02: "is al in gebruik. Wilt u liever inloggen?",
  modalDuplicatedUserText03:
    "Zowel het e-mailadres als het telefoonnummer zijn al in gebruik in verschillende accounts. Controleer de informatie of probeer in te loggen.",
  didntReciveCode: "Code niet ontvangen?",
  or: "of",
  phoneReviewInfo: "Voer hieronder uw telefoonnummer in.",
  loginDeletedAccountModalTitle: "Uw account opnieuw activeren?",
  loginDeletedAccountModalText:
    "Het lijkt erop dat uw account onlangs is verwijderd. Door verder te gaan met de inlogprocedure, wordt het account opnieuw geactiveerd en wordt het verzoek om de gegevens te verwijderen geannuleerd.",
  welcomeBack:"Welkom Terug"
  };
