import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localStorageUser from "../../localStorage/user";

// Import flags of languages available
import {ReactComponent as FR} from "../../assets/images/flags/FR.svg";
import {ReactComponent as GB} from "../../assets/images/flags/GB.svg";
import {ReactComponent as NL} from "../../assets/images/flags/NL.svg";

// Import translations available
import en from './en';
import fr from './fr';
import nl from "./nl";

// Define languages (same as imported translations)
const LANGUAGES = {
  en,
  fr,
  nl
};

const LANG_CODES = Object.keys(LANGUAGES);
const savedLanguage =  await localStorageUser.getUserLanguage();
const initialLanguage = savedLanguage || 'fr';

const LANGUAGE_DETECTOR = {
  init: Function.prototype,
  type: "languageDetector",
  async: true,
  detect: async (callback) => {
    const userLang = await localStorageUser.getUserLanguage();
    if (userLang.success && userLang.result) {
      initialLanguage = userLang.result;
    } else {
      if (LANG_CODES.indexOf(deviceLocales[0].languageCode) !== -1) {
        initialLanguage = deviceLocales[0].languageCode;
      }
    }
    callback(initialLanguage);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  // detect language
  .use(LANGUAGE_DETECTOR)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // set options
  .init({
    resources: LANGUAGES,
    react: {
      useSuspense: false,
    },
    lng: savedLanguage,
    fallbackLng: initialLanguage,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "common",
  });

  // Languages to make available to user change app language
  export const LANGUAGES_AVAILABLE = [
    { code: "en", label: i18n.t("common:en"), flag: <GB /> },
    { code: "fr", label: i18n.t("common:fr"), flag: <FR /> },
    { code: "nl", label: i18n.t("common:nl"), flag: <NL /> },
  ];
     
  // Define if language change is on submenu (submenu: true) or on settings screen (submenu: false)
  export const LANGUAGE_CHANGER = { submenu: true };