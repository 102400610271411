import React from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";

import DefaultTheme from "../themes";
import BottomBtnContainer from "../components/UI/BottomBtnContainer";
import { ReactComponent as SvgComponent } from "../assets/images/app_layout/logo.svg";
import SmallParagraph from "../components/UI/SmallParagraph";


export default function ResendCode() {
  const { t } = useTranslation();

  return (
    <View style={styles.body}>
      <View style={styles.container}>
        <View style={styles.img_container}>
          <SvgComponent style={styles.login_img} />
        </View>
        <BottomBtnContainer style={styles.bottom_container}>
          <SmallParagraph
            style={[styles.smallParagraph, styles.smallParagraph_color]}
          >
            App v1.0.0
          </SmallParagraph>
          <SmallParagraph style={styles.smallParagraph}>
            © reckon.ai, 2023
          </SmallParagraph>
        </BottomBtnContainer>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    height: "100vh",
  },
  container: {
    paddingHorizontal: DefaultTheme.GLOBAL_SPACES.paddingHorizontal,
    backgroundColor: DefaultTheme.COLORS.white,
    flex: 1,
  },
  img_container: {
    display: "flex",
    height: "90%",
    justifyContent: "center",
    alignItems: "center",
  },
  bottom_container: {
    marginBottom: "10%",
    justifyContent: "center",
    alignItems: "center",
  },
  smallParagraph: {
    marginTop: 0,
    textAlign: "center",
  },
  smallParagraph_color: {
    color: DefaultTheme.COLORS.grey2,
  },
});
