import React from "react";
import { StyleSheet, View } from "react-native-web";

import Header from "../components/Header";
import DefaultTheme from "../themes";


const Layout = ({
  children,
  header = true,
  headerLabel,
  logo,
  headerBack,
  headerBackName,
  headerBackParams,
  popNrScreens,
  route,
  backHome,
  withScrollview = true,
  styleToContainer,
  cameraView = false,
  style
}) => {
  return (
    <View
      style={[
        styles.body, style
      ]}
    >
      {header && (
        <Header
          label={headerLabel}
          logo={logo}
          back={headerBack}
          headerBackName={headerBackName}
          headerBackParams={headerBackParams}
          popNrScreens={popNrScreens}
          close={backHome}
          route={route}
        />
      )}
        <View style={[styles.container, styleToContainer]}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    height: "100vh",

  },
  container: {
    paddingHorizontal: DefaultTheme.GLOBAL_SPACES.paddingHorizontal,
    flex: 1,
  },
});

export default Layout;
