export default {
  myPurchases: "My Purchases",
  paymentMethods: "Payment Methods",
  accountInfo: "Account Info",
  resolutionCenter: "Resolution Center",
  emailReceipt: "Email Receipt Notifications",
  changePassword: "Change Password",
  languageSettings: "Change Language",
  deleteAccount: "Delete Account",
  logout: "Logout"
};
