import React from "react";
import { StyleSheet, Text } from "react-native-web";
import DefaultTheme from "../../themes";

const H2Title = ({ children, style }) => {
  return <Text style={[styles.h2, style]}>{children}</Text>;
};

const styles = StyleSheet.create({
  h2: {
    fontFamily: DefaultTheme.FONTS.Medium,
    textAlign: "center",
    fontSize: DefaultTheme.FONT_SIZES.h2,
    color: DefaultTheme.COLORS.grey5,
  },
});

export default H2Title;
