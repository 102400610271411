export default {
  noPurchasesTitle: "Nog geen aankopen",
  noPurchasesText:
    "Zodra u een aankoop voltooit, worden de details hier weergegeven. Ga ervoor!",
  purchase: "Aankoop",
  item: "item",
  microstore: "Microwinkel",
  paymentStatus: "Betalingsstatus",
  pending: "In behandeling",
  failed: "Mislukt",
  paid: "Betaald",
  gotAnIssue: "Heb je een probleem met deze aankoop?",
  createATicket: "Een probleem melden",
  partiallyPaid: "Gedeeltelijk betaald",
  inDebt: "In de schulden",
  totalPaid: "totaal betaald",
  debtStatus: "Schuldstatus",
  partiallySettled: "Gedeeltelijk geregeld",
  totalSettled: "Totaal vereffend",
  totalInDebt: "Totaal in de schulden",
  vatIncl: "incl. btw",
  vatExcl: "excl. btw",
  purchaseHistory: "Aankoopgeschiedenis",
};
