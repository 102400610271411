import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppLayout from "../appLayout/AppLayout";
import ResultMessages from "../components/ResultMessages";
import { useHistory } from "react-router-dom";
import { ReactComponent as SvgComponent } from "../assets/images/result_screens/something_wrong.svg";


export default function ResultMessageScreen({ navigation }) {
    const { t } = useTranslation();
    const history = useHistory();
    const route = history.location.state;
  // Props to AppLayout
  const header = route.params?.showHeader;
  const headerLabel = route.params?.headerLabel;
  const headerBack = route.params?.headerBack;
  const backHome = route.params?.backHome;

  // Props to ResultMessages
  const styleToComponent = route.params?.styleToComponent;
  const image = [<SvgComponent />];
  const title = t("resultScreens:noResponseTitle");
  const text = t("resultScreens:noResponseText");
  const primaryButton = [t("buttons:goBack"), () => history.goBack()];
  //const secondaryButton = route.params?.secondaryButton;
  const linkButton = [t("buttons:close"), () => history.push('/')];

  return (
    <AppLayout
      header={header}
      headerLabel={headerLabel}
      headerBack={headerBack}
      backHome={backHome}
      navigation={navigation}
      style={{ backgroundColor: "#fff" }}
    >
      <ResultMessages
        style={styleToComponent}
        image={image}
        title={title}
        text={text}
        primaryButton={primaryButton}
        //secondaryButton={secondaryButton}
        linkButton={linkButton}
      />
    </AppLayout>
  );
}
