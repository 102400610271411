export default {
  phoneNumber: "Numéro de télephone",
  searchCountry: "Rechercher un pays",
  forgotPassword: "Mot de passe oublié?",
  changePassword: "Modifier le mot de passe",
  deleteAccountModalTitle: "Tu es sûr de vouloir partir ?",
  deleteAccountModalText:
    "Cette action supprimera définitivement toutes tes données conformément à notre politique de confidentialité.",
  name: "Prénom",
  nameInfo:
    "Entre ton prénom et ton nom tel qu'il figure sur ta carte d'identité.",
  emailAddress: "Adresse E-mail",
  password: "Mot de passe",
  newPassword: "Nouveau mot de passe",
  checkTermsAndPrivacy: "En cochant cette case, j'accepte ",
  termsConditions: "les conditions d'utilisation de Reckon.ai ",
  and: "et ",
  privacyPolicy: "la politique de confidentialité",
  verificationCodeInfoPart1:
    "Nous avons envoyé un code de vérification sur votre téléphone",
  verificationCodeInfoPart2: "Le code est valable pendant",
  seconds: " secondes",
  profileAllSetTitle: "Bonne nouvelle ! Ton profil est prêt à être utilisé!",
  profileAllSetText:
    "Maintenant que ton profil est défini, nous allons configurer ta méthode de paiement.",
  modalDuplicatedUserTitle: "Il semble que tu as déjà un compte",
  modalDuplicatedUserText01: "L'adresse e-mail",
  modalDuplicatedUserText011: "Le numéro de télephone",
  modalDuplicatedUserText02:
    "est déjà utilisée. Veux-tu te connecter à la place",
  modalDuplicatedUserText03:
    "Both email and phone number are already in use in different accounts. Please review the info or try to login.",
  didntReciveCode: "Tu n’as pas reçu le code?",
  or: "ou",
  phoneReviewInfo: "Merci d’insérer ton numéro de téléphone ci-dessous.",
  loginDeletedAccountModalTitle: "Réactiver ton compte?",
  loginDeletedAccountModalText:
    "Il semble que ton compte ait été récemment supprimé. En poursuivant la procédure de connexion, le compte sera réactivé et la demande de suppression des données sera annulée.",
  welcomeBack:"Bienvenue"
  }; 