import React from "react";
import { Text, StyleSheet, View, Pressable } from "react-native-web";
import { HiArrowLeft } from "react-icons/hi";

import DefaultTheme from "../themes";
import {ReactComponent as LogoHeader} from "../assets/images/app_layout/logo.svg";
import {ReactComponent as Close} from "../assets/icons/close.svg";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  const history = useHistory();

  return (
    <View style={styles.header_container}>
      {props.back ? (
        <Pressable
          style={{ paddingVertical: 4, paddingLeft:"5.7%"}}
          onPress={() => {
            props.headerBackName ? history.push({pathname:props.headerBackName}) : history.goBack();
            //props.navigation.goBack(null);
/*             props.headerBackName
              ? props.navigation.replace(
                  props.headerBackName,
                  props.headerBackParams
                )
              : props.navigation.pop(
                  props.popNrScreens ? props.popNrScreens : 1
                ); */
          }}
        >
          <HiArrowLeft
            style={styles.icon}
            color={DefaultTheme.COLORS.primary}
            size={27}
          />
        </Pressable>
      ) : (
        <Text style={styles.left}></Text>
      )}
      <View style={{ flex: 1 }}>
        {props.label && <Text style={styles.title}>{props.label}</Text>}
        {props.logo && (
          <View style={styles.logo_container}>
            <LogoHeader />
          </View>
        )}
      </View>
      {props.close ? (
        <Pressable
          style={styles.pressable_right}
          onPress={() => {
            history.push("Home");
          }}
        >
          <Close style={styles.icon_style} />
        </Pressable>
      ) : (
        <Text style={styles.right}></Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header_container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: DefaultTheme.COLORS.border,
    position: "relative",
  },
  pressable_left: {
    paddingLeft: "5.7%",
    paddingVertical: 4,
  },
  pressable_right: {
    paddingRight: "5.7%",
    paddingVertical: 4,
  },
  icon_style: {
    color: DefaultTheme.COLORS.primary,
  },
  title: {
    fontFamily: DefaultTheme.FONTS.Medium,
    textAlign: "center",
    color: DefaultTheme.COLORS.grey5,
    fontSize: DefaultTheme.FONT_SIZES.header_title,
    paddingVertical: 30,
  },
  logo_container: {
    paddingVertical: 30,
    alignSelf: "center",
    maxheight:"10%"
  },
  left: {
    textAlign: "left",
    paddingLeft: "5.7%",
    paddingRight: 10,
  },
  right: {
    textAlign: "right",
    paddingRight: "5.7%",
    paddingLeft: 10,
  },
});

export default Header;
