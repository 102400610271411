export default {
  signUp: "Sign Up",
  logIn: "Login",
  resetHere: "Reset here",
  next: "Next",
  confirm: "Confirm",
  setNewPassword: "Set New Password",
  resendHere: "Resend here",
  reviewProfile: "Review profile",
  letShop: "Let’s shop",
  saveAndContinue: "Save and Continue",
  resendValidationCode: "Resend Validation Code",
  setUpPaymentMethod: "Set up payment method",
  skip: "Skip",
  skipConfig: "Skip",
  accept: "Accept",
  save: "Save",
  processing: "Processing",
  shopNow: "Shop Now",
  close: "Close",
  submit: "Submit",
  addCreditCard: "Add Credit Card",
  deleteCreditCard: "Delete Credit Card",
  deleteAccount: "Delete Account",
  saveChanges: "Save Changes",
  createNewTicket: "Create New Ticket",
  createTicket: "Create Ticket",
  confirmTicket: "Confirm Ticket",
  logout: "Log Out",
  addPaymentMethod: "Add payment method",
  addNewPayment: "Add new payment",
  continueSettlement: "Continue to debt settlement",
  settleTheDebt: "Settle the debt",
  settleDebt: "Settle debt",
  confirmSettlement: "Confirm settlement",
  changePreferredPayment: "Add or Change preferred payment",
  tryAgain: "Try again",
  saveAndSettleTheDebt: "Save and settle the debt",
  cancel: "Cancel",
  validate: "Validate",
  goBack: "Go Back",
  backToAccountInfo: "Back To Account Info",
  backToHome: "Back To Home",
  backToPayments: "Back To Payments",
  reviewCreditCardInfo: "Review Credit Card Info",
  reviewAccountInfo: "Review account info",
  reviewPhoneNumber: "Review phone number",
  seeDebtDetails: "See Debt Details",
  logInReactivateAccount:"Log In & Reactivate Account",
  goToPurchaseHistory: "Go to Purchase History"
};
