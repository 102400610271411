export default {
  noPurchasesTitle: "Aucun achat pour le moment",
  noPurchasesText:
    "Dès que tu auras effectué un achat, ses détails apparaîtront ici. Allez-y!",
  purchase: "Achat",
  item: "élément",
  microstore: "Microstore",
  paymentStatus: "Statut du paiement",
  pending: "En attente",
  failed: "Échoué",
  paid: "Payé",
  gotAnIssue: "Tu as un problème avec cet achat?",
  createATicket: "Contacte-nous",
  partiallyPaid: "Partiellement payé",
  inDebt: "En dette",
  totalPaid: "Total payé",
  debtStatus: "Statut de la dette",
  partiallySettled: "Partiellement réglée",
  totalSettled: "Total réglé",
  totalInDebt: "Total de la dette",
  vatIncl: "TVA incl.",
  vatExcl: "hors TVA",
  purchaseHistory: "Historique d'achat",
};
			
			
			
			
			
			
			
			
			
			
			
			