export default {
  signUp: "S'inscrire",
  logIn: "Connexion",
  resetHere: "Réinitialiser ici",
  next: "Suivant",
  confirm: "Confirmer",
  setNewPassword: "Définir ce nouveau mot de passe",
  resendHere: "Renvoie-le ici",
  reviewProfile: "Mon Profil",
  letShop: "Allons faire les courses",
  saveAndContinue: "Sauvegarder et continuer",
  resendValidationCode: "Renvoyer le code de validation",
  setUpPaymentMethod: "Configurer le mode de paiement",
  skip: "Suivant",
  skipConfig: "Configurer plus tard",
  accept: "Accepter",
  save: "Sauvez",
  processing: "Traitement en cours",
  shopNow: "Acheter maintenant",
  close: "Fermer",
  submit: "Envoyer",
  addCreditCard: "Ajouter une carte de crédit",
  deleteCreditCard: "Supprimer la carte de crédit",
  deleteAccount: "Supprimer le compte",
  saveChanges: "Sauvegarder les changements",
  createNewTicket: "Signaler un problème",
  createTicket: "Envoyer",
  confirmTicket: "Confirmer le message",
  logout: "Déconnexion",
  addPaymentMethod: "Ajout d'un mode de paiement",
  addNewPayment: "Ajouter un nouveau paiement",
  continueSettlement: "Poursuivre le règlement de la dette",
  settleTheDebt: "Régler la dette",
  settleDebt: "Régler la dette",
  confirmSettlement: "Confirmer le règlement",
  changePreferredPayment: "Ajouter ou modifier un paiement préféré",
  tryAgain: "Essaye à nouveau",
  saveAndSettleTheDebt: "Sauver et régler la dette",
  cancel: "Fermer",
  validate: "Valider",
  goBack: "Retourner",
  backToAccountInfo: "Retour aux informations du compte",
  backToHome: "Retour à l’accueil",
  backToPayments: "Retourner aux paiements",
  reviewCreditCardInfo: "Revoir les informations de le paiement",
  reviewAccountInfo: "Consulter le Compte",
  reviewPhoneNumber: "Vérifier le numéro de téléphone",
  seeDebtDetails: "Voir les détails de la dette",
  logInReactivateAccount: "Se connecter et réactiver le compte",
  goToPurchaseHistory: "Aller à l’historique des achats"
};
