export default {
  myPurchases: "Mijn aankopen",
  paymentMethods: "Betaalmethoden",
  accountInfo: "Account informatie",
  resolutionCenter: "Helpcenter",
  emailReceipt: "E-mailontvangstmeldingen",
  changePassword: "Wachtwoord wijzigen",
  languageSettings: "Taal wijzigen",
  deleteAccount: "Account verwijderen",
  logout: "Afmelden"
};
			
			
			