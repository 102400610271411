import React, { useState, useEffect } from "react";
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';

import DefaultTheme from "../themes";
import RadioButton from "./UI/RadioButton";

import {
    noResponseParams,
    notOkPageParams,
} from "./Helpers/ResultMessagesHelper";

import localStorageUser from "../localStorage/user";
import AuthService from "../services/auth-service";

import { LANGUAGES_AVAILABLE } from "../constants/translations/IMLocalize";

function MenuLanguageSelector({ style }) {
    const { t, i18n } = useTranslation();
    const selectedLanguageCode = i18n.language;
    const [id, setId] = useState("");

    useEffect(() => {
        async function getUserFromStorage() {
            const getUser = await localStorageUser.getUser();
            if (typeof getUser.result === "string") {
                getUser.result = JSON.parse(getUser.result);
            }
            if (getUser.result) {
                setId(getUser.result._id);
            }
        }
        getUserFromStorage();
    }, []);

    async function changeLanguage(code) {
        let res = await AuthService.updateUser({ locale: code }, id);
        if (res.success) {
            await AuthService.getUser({ idUser: id });
            await localStorageUser.setUserLanguage(code);
            i18n.changeLanguage(code);
        } else if (res.errorMessage.noResponse) {
            history.push("/error", {
                params: noResponseParams,
            });
        } else {
            history.push("/error", {
                params: notOkPageParams,
            });
        }
    }

    return (
        <View style={[styles.language_select_container, style]}>
            {LANGUAGES_AVAILABLE.map((language) => {
                const selectedLanguage = language.code === selectedLanguageCode;
                return (
                    <RadioButton
                        key={language.code}
                        myKey={language.code}
                        disabled={selectedLanguage}
                        onPress={() => changeLanguage(language.code)}
                        selected={selectedLanguage}
                        iconElement={language.flag}
                        optionsContainerStyle={{
                            paddingVertical: 0,
                            borderBottomWidth: 0,
                        }}
                        text={language.code.toUpperCase()}
                        textContainerStyle={styles.text_container}
                        textStyle={styles.text}
                    />
                );
            })}
        </View>
    );
};

const styles = StyleSheet.create({
    language_select_container: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 20,
        paddingInline: 30,
    },
    text_container: {
        marginLeft: 8.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    text: {
        marginLeft: 8.5,
        color: DefaultTheme.COLORS.white,
        lineHeight: 15
    },
});

export default MenuLanguageSelector;