import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
} from "react-native";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppLayout from "../../appLayout/AppLayout";
import DefaultTheme from "../../themes";
import { getFormattedDateDayMonthHour } from "../../constants/utils/date";
import DataList from "../../components/DataList";
import SplashScreen from "../SplashScreen";
import {
  noResponseParams,
  notOkPageParams,
} from "../../components/Helpers/ResultMessagesHelper";
import ContainerSpaceBetween from "../../components/UI/ContainerSpaceBetween";

import BuybyeService from "../../services/buybye-service";

//Dummy data
import { ticketSubjectsArr } from "../../data/ticket-subjects-dummy-data";
// end

function Detail({ selectedPurchase, currency, totalDebt, pay }) {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const subjectTicket = ticketSubjectsArr.find(
    (subject) => subject.title === "Other"
  );
  const statusColor =
    selectedPurchase.status === "Paid"
      ? DefaultTheme.COLORS.success
      : selectedPurchase.status === "Failed"
        ? DefaultTheme.COLORS.error
        : DefaultTheme.COLORS.grey4;


  let totalWithoutVat = 0;
  if (selectedPurchase.totalValue !== 0) {
    for (let i = 0; i < selectedPurchase.products.length; i++) {
      if (
        typeof selectedPurchase.products[i].taxValue !== "undefined" &&
        selectedPurchase.products[i].taxValue !== ""
      ) {
        totalWithoutVat +=
          (selectedPurchase.products[i].price *
            selectedPurchase.products[i].amount) /
          (1 + selectedPurchase.products[i].taxValue / 100);
      }
    }
  }

  return (
    <AppLayout
      headerLabel={t("common:headerPurchaseDetail")}
      headerBack={true}
      navigation={history}
    >
      <DataList
        borderAtBottom={false}
        styleOuterView={{ paddingBottom: 28 }}
        title={{
          text: getFormattedDateDayMonthHour(
            selectedPurchase.endDate,
            i18n.language
          ),
          style: { fontFamily: DefaultTheme.FONTS.Regular }
        }}
        lineOneLeft={{
          label: t("purchases:purchase") + ": ",
          text:
            totalWithoutVat === 0
              ? selectedPurchase.sessionUUID
              : selectedPurchase.sessionUUID +
              " ・ " +
              selectedPurchase.totalProduct +
              " " +
              t("purchases:item") +
              (selectedPurchase.totalProduct > 1 ? "s" : ""),
          color: DefaultTheme.COLORS.grey2,
        }}
        lineOneRight={{
          label: totalWithoutVat !== 0 ? t("purchases:vatExcl") : " ",
          text:
            totalWithoutVat !== 0
              ? currency + " " + totalWithoutVat?.toFixed(2).replace(".", ",")
              : selectedPurchase.totalProduct +
              " " +
              t("purchases:item") +
              (selectedPurchase.totalProduct > 1 ? "s" : ""),
          style: { fontFamily: DefaultTheme.FONTS.Regular, fontSize: 12, opacity: "50%" }
        }}
        lineTwoLeft={{
          label: t("purchases:microstore") + ": ",
          text: selectedPurchase.machineCode,
          color: DefaultTheme.COLORS.grey2,
        }}
        lineTwoRight={{
          label: totalWithoutVat !== 0 ? t("purchases:vatIncl") + " " : "",
          text:
            selectedPurchase.currency +
            " " +
            selectedPurchase.totalValue?.toFixed(2).replace(".", ","),
          style: [styles.darker_text, { fontFamily: DefaultTheme.FONTS.Medium }],
        }}
        lastWideLine={
          typeof selectedPurchase.taxLocation !== "undefined" &&
            selectedPurchase.taxLocation !== ""
            ? {
              text: selectedPurchase.taxLocation,
              color: DefaultTheme.COLORS.grey3,
              style: { textAlign: "center" },
            }
            : null
        }
      />
      {totalDebt ? (
        <View
          style={[
            styles.status_container,
            { marginBottom: 20, borderColor: DefaultTheme.COLORS.error },
          ]}
        >
          <View style={{ flexDirection: "row", paddingBottom: 8 }}>
            <Text style={styles.lighter_text}>
              {t("purchases:paymentStatus")}:
            </Text>
            <Text style={styles.red_text}>
              &nbsp;
              {pay == 0 ? t("purchases:inDebt") : t("purchases:partiallyPaid")}
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {pay != 0 ? (
              <Text style={styles.lighter_text}>
                {t("purchases:totalPaid")}: {currency}{" "}
                {pay?.toFixed(2).replace(".", ",")}
              </Text>
            ) : null}
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.lighter_text}>{t("purchases:inDebt")}:</Text>
              <Text style={styles.red_text}>
                &nbsp;{currency} {totalDebt?.toFixed(2).replace(".", ",")}
              </Text>
            </View>
          </View>
        </View>
      ) : null}
      {selectedPurchase.status && (
        <View
          style={[
            styles.status_container,
            { marginBottom: 19, borderColor: statusColor },
          ]}
        >
          <Text style={styles.lighter_text}>
            {t("purchases:paymentStatus") + ": "}
            <Text
              style={[
                {
                  fontFamily: DefaultTheme.FONTS.Medium,
                  color: statusColor,
                },
              ]}
            >
              {" "}
              {selectedPurchase.status}
            </Text>
          </Text>
        </View>
      )}
      <View style={styles.container_borderBottom}></View>
      {selectedPurchase.products.map((product) => {
        return (
          <ContainerSpaceBetween
            key={product._id}
            style={styles.container_borderBottom}
          >
            <ContainerSpaceBetween
              style={
                {
                  paddingVertical: 12,
                  maxWidth:
                    typeof product.taxValue !== "undefined" &&
                      product.taxValue !== ""
                      ? "60%"
                      : "68%",
                }
              }
            >
              <Image
                style={styles.product_image}
                source={{
                  uri: product.image,
                  headers: {
                    Accept: "*/*",
                  },
                }}
                resizeMode="cover"
                objectFit="cover"
                accessible={true}
                accessibilityLabel={"Image of: " + product.name}
              />
              <Text style={[styles.darker_text, { maxWidth: "100%" }]}>
                {product.name}
                {typeof product.taxValue !== "undefined" &&
                  product.taxValue !== "" ? (
                  <Text style={styles.lighter_text}>
                    {"\n"}
                    {product.amount} {t("purchases:item")}
                    {product.amount > 1 && "s"}
                  </Text>
                ) : null}
              </Text>
            </ContainerSpaceBetween>
            <View style={{ alignItems: "flex-end" }}>
              <View style={styles.lighter_text}>
                {typeof product.taxValue !== "undefined" && product.taxValue !== "" ? (
                  <Text>
                    <Text style={{ fontSize: 10 }}>{t("purchases:vatExcl")} </Text> {currency}{" "}
                    {(product.price / (1 + product.taxValue / 100))
                      ?.toFixed(2)
                      .replace(".", ",")}
                  </Text>
                ) : (
                  <Text>
                    {product.amount} {t("purchases:item")}
                    {product.amount > 1 ? "s" : ""}
                  </Text>
                )}
              </View>
              <Text
                style={[
                  styles.darker_text,
                  { fontFamily: DefaultTheme.FONTS.Medium, paddingTop: 10 },
                ]}
              >
                <Text
                  style={[styles.lighter_text, { fontFamily: DefaultTheme.FONTS.Medium, fontSize: "10px" }]}
                >
                  {typeof product.taxValue !== "undefined" &&
                    product.taxValue !== ""
                    ? product.taxValue + "% " + t("purchases:vatIncl") + " "
                    : ""}
                </Text>
                <Text>
                  
                  {currency + " " + product.price?.toFixed(2).replace(".", ",")}
                </Text>
              </Text>
            </View>
          </ContainerSpaceBetween>
        );
      })}
    </AppLayout>
  );
}

export default function PurchasesDetail() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const purchaseId = history.location?.purchaseId;
  const [selectedPurchase, setSelectedPurchase] = useState();
  const [currency, setCurrency] = useState();
  const [totalDebt, setTotalDebt] = useState();
  const [pay, setPay] = useState();

  useEffect(() => {
    async function main() {
      let res = await BuybyeService.getShop(purchaseId);
      if (res.success) {
        setTotalDebt(res.result.debt);
        setPay(res.result.pay)
        setSelectedPurchase(res.result);
        setCurrency(res.result.currency);
      } else if (res.errorMessage.noResponse) {
        history.push("/error", {
          params: noResponseParams,
        });
      } else {
        history.push("/error", {
          params: notOkPageParams(
            res.errorMessage.message + "\n " + t("resultScreens:tryAgainLater")
          ),
        });
      }
    }
    main();
  }, []);

  if (!selectedPurchase) {
    return <SplashScreen />;
  }
  return (
    <Detail
      selectedPurchase={selectedPurchase}
      currency={currency}
      totalDebt={totalDebt}
      pay={pay}
    />
  );
}

const styles = StyleSheet.create({
  container_borderBottom: {
    borderBottomColor: DefaultTheme.COLORS.grey1,
    borderBottomWidth: 1,
  },
  info_container: {
    paddingVertical: 11,
  },
  darker_text: {
    fontFamily: DefaultTheme.FONTS.Regular,
    fontSize: DefaultTheme.FONT_SIZES.p,
    lineHeight: 17.78,
    color: DefaultTheme.COLORS.grey5,
  },
  lighter_text: {
    fontFamily: DefaultTheme.FONTS.Regular,
    fontSize: 12,
    lineHeight: 14.22,
    color: DefaultTheme.COLORS.grey3,
  },
  red_text: {
    fontFamily: DefaultTheme.FONTS.Medium,
    fontSize: 12,
    lineHeight: 14.22,
    color: DefaultTheme.COLORS.error,
  },
  status_container: {
    paddingHorizontal: 18,
    paddingVertical: 13,
    borderWidth: 1,
    borderColor: DefaultTheme.COLORS.grey5,
    borderRadius: 5,
  },
  product_image: {
    width: 40,
    height: 40,
    borderRadius: 80,
    marginRight: 10,
  },
});

