import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import DefaultTheme from "../../themes";
import Paragraph from "./Paragraph";

const RadioButton = ({
  disabled,
  iconElement,
  infoText,
  myKey,
  onPress,
  optionsContainerStyle,
  pressableStyle,
  selected,
  tag,
  text,
  textContainerStyle,
  textStyle,
}) => {
  return (
    <Pressable
      myKey={myKey}
      disabled={disabled}
      onPress={onPress}
      style={pressableStyle}
    >
      <View style={[styles.radio_options_container, optionsContainerStyle]}>
        <View style={styles.outer_circle}>
          <View
            style={[
              styles.inner_circle,
              selected ? styles.selected : styles.unselected,
            ]}
          ></View>
        </View>
        <View style={[styles.text_container, textContainerStyle]}>
          {iconElement}
          <Paragraph style={textStyle}>{text}</Paragraph>
          {tag ||
            (infoText && (
              <View style={styles.label_container}>
                {tag ? (
                  <View style={styles.label}>
                    <Text style={styles.label_text}>{tag}</Text>
                  </View>
                ) : null}
                {infoText && (
                  <Text style={styles.small_lighter}>{infoText}</Text>
                )}
              </View>
            ))}
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  radio_options_container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12,
    borderBottomColor: DefaultTheme.COLORS.grey1,
    borderBottomWidth: 1,
  },
  outer_circle: {
    width: 20,
    height: 20,
    borderRadius: 11,
    backgroundColor: DefaultTheme.COLORS.grey1,
    justifyContent: "center",
    alignItems: "center",
  },
  inner_circle: {
    width: 10,
    height: 10,
    borderRadius: 6,
  },
  selected: {
    backgroundColor: DefaultTheme.COLORS.grey5,
  },
  unselected: {
    backgroundColor: DefaultTheme.COLORS.grey1,
  },
  text_container: {
    marginLeft: 20,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  label_container: {
    marginTop: 12,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    backgroundColor: DefaultTheme.COLORS.grey4,
    paddingVertical: 3,
    paddingHorizontal: 4,
    marginRight: 8,
    borderRadius: 4,
  },
  label_text: {
    color: DefaultTheme.COLORS.white,
    fontFamily: DefaultTheme.FONTS.Regular,
    fontSize: DefaultTheme.FONT_SIZES.small,
    textAlign: "center",
  },
  small_lighter: {
    fontSize: 12,
    color: DefaultTheme.COLORS.grey2,
    fontFamily: DefaultTheme.FONTS.Regular,
  },
});

export default RadioButton;
